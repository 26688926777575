<script setup lang="ts">
import locales from '~/assets/data/locales.json'

const route = useRoute()
const config = useRuntimeConfig()
const region = useCookie('region', { path: '/', domain: config.public.COOKIE_DOMAIN })

const alternateLinks = locales.map((locale: any) => ({ rel: 'alternate', hreflang: locale.lang, href: useSetSubdomain(locale.region) }))
useHead({
  titleTemplate: (title: string) => computed(() => `${route.meta.title || title}`).value,
  meta: [
    { name: 'robots', content: 'none' },
  ],
  link: [
    { rel: 'canonical', href: computed(() => `${useSetSubdomain2(region.value) + route.path}`) },
    { rel: 'alternate', hreflang: 'en', href: config.public.SITEMAP_HOST },
    ...alternateLinks,
  ],
})

function useSetSubdomain2(locale: string) {
  // cannot call the one on composeable coz duplicated config
  const subdomain = config.public.SITEMAP_HOST.split('.')
  const prefix = config.public.ENV === 'development' || config.public.ENV === 'staging' ? subdomain[0].split('-')[0] : subdomain[0]
  if (config.public.ENV === 'development' || config.public.ENV === 'staging')
    return `${locale ? `${prefix}-${locale}.${subdomain.slice(1).join('.')}` : `${prefix}.${subdomain.slice(1).join('.')}`}`
  else
    return `${locale ? `${prefix.split('//')[0]}//${locale}.${prefix.split('//')[1]}.${subdomain.slice(1).join('.')}` : `${prefix}.${subdomain.slice(1).join('.')}`}`
}
</script>

<script lang="ts">
export default {
  layout: 'user',
}
</script>

<template>
  <div class="min-h-screen relative text-sm text-gray-700 flex flex-col bg-gray-50">
    <BaseOrganismsMainNavbar />

    <div class="w-full max-w-4xl lg:max-w-5xl xl:max-w-6xl mx-auto pt-4 lg:pt-10 pb-20 lg:pb-40">
      <BaseOrganismsUserMenu />
      <DashboardVerifyPhone class="<lg:px-4" />
      <div class="<lg:px-4">
        <slot />
      </div>
    </div>
    <BaseOrganismsMainFooter class="mt-auto" />
  </div>
</template>
